import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "GMO 포함 식품, 표시해야 할까?",
  "slug": "data7",
  "date": "2022-03-09T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb07.png",
  "featuredImg": "../images/featured/featured07.png",
  "summary": "한국은 식용 GMO를 연간 200만t씩 수입한다. 국민 한 사람이 해마다 40kg 이상의 GMO를 먹는 것으로 추정된다.",
  "tags": ["숙의형조사", "GMO", "식품"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`GMO(Genetically Modified Organism)는 유전자 변형(재조합) 농산물의 영문 약자다. 생산량을 증대하고 유통과 가공의 편리함을 위해 기존 육종 방법으로는 생길 수 없는 형질이나 유전자를 갖도록 개발된 농산물이다.`}<br parentName="p"></br>{`
`}{`한국은 식용 GMO를 연간 200만t씩 수입한다. 국민 한 사람이 해마다 40kg 이상의 GMO를 먹는 것으로 추정된다. 1인당 연간 쌀 소비량 62kg과 비교해도 적지 않은 양이다. 수입 물량 대부분은 콩, 옥수수 등이고 간장 같은 장류와 식용유에 쓰인다. GMO 수용은 찬반이 엇갈린다. 생산 효율성과 편리함을 강조하는 입장도 있지만, 한편에서는 안정성에 대한 불안과 의심을 거두지 않는다.`}</p>
    <h2>{`GMO 완전 표시제 시행`}</h2>

    <ChartBase type={'bar'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <p>{`GMO에 반대하는 사람들은 최소한 식품 소비자가 선택할 수 있도록 GMO 포함 여부를 표시해야 한다고 주장한다. 현재는 유통되는 식품에 GMO 포함 여부가 표시되지 않는다. 2018년 3월에는 청와대 국민청원 게시판에 GMO 완전 표시제를 주장하는 청원이 올라왔고 수십만 명이 이에 동의했다. 정부는 안전성 문제에 이견이 있고, 완전 표시제를 시행할 경우 물가 상승과 통상 마찰의 우려 가 있어 조심스럽게 검토해야 한다는 원론적 입장이다.`}<br parentName="p"></br>{`
`}{`GMO 완전 표시제를 시행하는 것이 옳을까를 주제로 하여 숙의형 시민 토론을 열었다. 중·고등학생 70여 명이 모였고 12개 테이블에 나눠 앉은 뒤 1차로 테이블별 상호 토론을 했다. 이어서 2차 토론에서는 전문가 2명이 찬반 발제문을 발표했다. 이를 바탕으로 3차 토론을 벌여 결론을 냈다. 토론 참가자들에게는 작은 계산기처럼 생긴 투표기가 주어졌고 1차 토론 전, 1차 토론 후, 3차 토론 후에 찬반 투표를 했다.`}<br parentName="p"></br>{`
`}{`토론 전 조사에서는 GMO 완전 표시제 시행에 대해 597%인 12명이 찬성했고, 7명이 반대했다. 유보가 22명이었다. 학교 급식에서 GMO 식품 금지에 대해서는 찬성 28명, 반대 21명, 유보 18명이었다.`}<br parentName="p"></br>{`
`}{`토론 중에 학교 급식 문제를 주로 다룬 1차 토론에서 학생들은 안전성 (32)과 비용 (14명)에 관한 고민이 가장 많았다. 소비자 선택권 (9명)과 알 권리(9명)는 상대적으로 관심이 부족했다.`}</p>
    <h3>{`토론 전후 참여자들의 입장은 어떻게 변했나?`}</h3>
    <p>{`1차 토론 후 조사에서 완전 표시제에는 찬성 53명, 반대 14명, 유보 8명으로 찬성 의견이 늘었다. 그러나 '학교 급식에서 GMO 식품 금지를 다시 조사하자 찬성 32명, 반대 31명, 유보 11명으로 나왔다. 반대가 더 많이 늘어난 것이다.`}<br parentName="p"></br>{`
`}{`이후 2차 토론이 시작됐다. 전문가들은 “완전 표시제는 GMO가 안전하지 않기때문에 요구하는 것이 아니라, 소비자에게 제대로 정보를 알려주라”는 알 권리 요구와 “한국에서 GMO에 대한 우려가 크지만, 실제 수입 품목이 많지 않고 엄격하게 관리되고 있기 때문에 현재로선 안전 문제나 국내 재배 가능성이 없다”는 효율성 관점이 부딪쳤다.`}<br parentName="p"></br>{`
`}{`전문가 토론을 들은 참가자들 간의 3차 토론이 진행됐다. 3차 토론 직후 조사에서 GMO 안전성에 대해서는 유보한다(27명)는 입장이 가장 많았으나 완전 표시제에 대해서 성분은 알고 선택하고 싶다(53명)는 의견이 압도적이었다.`}<br parentName="p"></br>{`
`}{`최종 투표에서는 완전 표시제에 찬성하는 사람이 81%인 55명으로 늘었다. 하지만 학교 급식에서 GMO를 금지하자는 것에는 33명이 찬성하고 34명이 반대했 다. 모순된 결과로 보이지만, '안전한지 확실히 알 수 없으나 어떤 성분이 들어 있는지 알고 먹어야 한다는 합리적 판단이 엿보인다.`}</p>
    <h6>{`*<경향신문>과 공공의창'이 함께 기획했으며 수행은 여론조사기관 코리아스픽스가 맡았다. 토론회는 2018년 5월 12일 서울 마포중앙도서관에서 중·고등학생 71명이 참여해 진행됐고 퍼실리테이터가 토론을 보조했다. 이와 별개로 코리아스픽스가 전국 성인남녀 803명을 상대로 여론조사를 진행했다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      